.react-datepicker__input-container{
  width: 100%;
}

.react-datepicker__input-container>input {
  padding: 18px;
  font-weight: 500;
  font-size: medium;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
}