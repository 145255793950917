.operation-pad {
  width: 100%;
  position: fixed;
  background-color: rgb(53 39 129);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.bottom {
  bottom: 0;
  left: 0;
  height: 60px;
}

.top {
  top: 50px;
  left: 0;
  height: 55px;
  padding-top: 20px;
}

.btn {
  padding: 8px;
  margin: 0 10px;
}

.icon-btn {
  background-color: transparent;
  border-style: none;
}

.sign-btn {
  border: 1px solid white;
  border-radius: 2px;
  margin: 0px !important;
  position: absolute;
  right: 5%;
  width: 10%;
  height: 65%;
}

.sign-label {
  color: white;
}

.information-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-line {
  flex: 50%;
}

/* @media screen and (min-width: 300px) {
  .top {
    top: 10vw;
  }
}
@media screen and (min-width: 350px) {
  .top {
    top: 9vw;
  }
}
@media screen and (min-width: 408px) {
  .top {
    top: 10vw;
  }
}
@media screen and (min-width: 650px) {
  .top {
    top: 9vw;
  }
}
@media screen and (min-width: 1000px) {
  .top {
    top: 8vw;
  }
} */

.float-right {
  float: right;
}

.icon {
  font-size: 100;
  color: white;
  margin-right: 30;
}

.btn:hover {
  cursor: pointer;
}

.email-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  height: calc(78vh * 0.9);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.sign-modal-flex {
  margin: 5px;
  display: flex;
  justify-content: space-between;
}

.sign-modal-btn {
  background-color: lightblue;
  border-style: none;
}

.p-h2 {
  font-weight: bolder;
  font-size: 90%;
}

.p-h3 {
  font-weight: bold;
  font-size: 70%;
}

.email-body {
  width: 100%;
  height: 110px;
  border: 2px solid black;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 20px;
}

.email-box {
  margin: 4px;
}

.link-content {
  color: blue;
}

.flex-right {
  position: absolute;
  right: 10px;
}

.vertical-center {
  display: flex;
  align-items: center;
}
