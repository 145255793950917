.WindowTable,
.WindowTable th,
.WindowTable td {
  font-size: 12px;
  border: 1px solid black;
  border-collapse: collapse;
  padding: 0px;
}

.WindowTable {
  width: 100%;
}

.WindowTable th,
.WindowTable td {
  text-align: center;
}

.WindowTable th {
  font-size: 18px;
  font-family: TimesNewRomanPS-boldMT_4g;
}

.WindowTable th,
.WindowTable td * {
  text-align: center;
  height: 30px;
}

.WindowTable td * {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .WindowTable .CheckBoxCell {
  width: 39px;
} */

/* .WindowTable tr {
  height: 39px;
} */

/* #form128_3 {
  z-index: 2;
  padding: 0px;
  position: absolute;
  left: 101px;
  top: 216px;
  width: 75px;
  height: 25px;
  color: rgb(0, 0, 0);
  text-align: left;
  background-color: rgb(255, 255, 255);
  border: none;
  font: normal 18px Arial, Helvetica, sans-serif;
} */

.table-input {
  border-style: none;
  width: 90%;
  line-height: 190%;
}

.table-select {
  border-style: none;
}

.col-0 {
  width: 30px;
}

.col-1 {
  width: calc(100% / 24);
}

.col-3 {
  width: calc(100% / 24 * 3);
}

.col-5 {
  width: calc(100% / 24 * 5);
}
