.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 80%;
}

.popup-modal {
  z-index: 100;
}

.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: calc(80vw * 0.6);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.sign-container {
  width: 200px;
  height: 50px;
}

.sign-button {
  border: none;
}

.yellow-background {
  background-color: yellow;
  background-image: url('../../../public/images/emtpy-sign.png');
}

.white {
  background-color: white;
}

.sign-img {
  display: block;
  margin: 0 auto;
}

.signature {
  display: inline-block;
}

.modal-btn {
  padding: 2% 5%;
  margin: 0 2%;
}
