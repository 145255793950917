.home-year {
  padding: 0 0 0 10px;
  letter-spacing: 1px;
}

.year-input {
  width: 90px;
}

.year-sign {
  padding: 0 4px 0 0;
}
