.cf_container {
  margin-top: 60px;
}

.cf_table_convert {
  width: 90%;
  margin: auto;
  border-spacing: 0px;
  border-collapse: collapse;
}

.cf_table_convert td {
  border: 2px solid black;
  border-collapse: collapse;
}

.cf_table_convert thead > tr > th {
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0 0 2px 0;
  border: 2px solid gray;
}

.cf_table_convert .gray_title {
  width: 20%;
  font-size: 18px;
  text-align: left;
  position: relative;
}

.cf_table_convert .gray_background {
  position: absolute;
  top: 1px;
  left: 0;
}

.cf_table_convert .gray_content {
  position: absolute;
  top: 12px;
  left: 5px;
}

.cf_table_convert .total {
  font-size: 20px;
  border-style: none;
  position: relative;
}

.cf_table_convert .total .commission-total {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 135px;
}

.cf_table_convert .total .commission-total-img,
.cf_table_convert .bonus .commission-total-img {
  position: absolute;
  top: 0;
  left: -1px;
}

.cf_table_convert .bonus .bonus-total {
  text-align: center;
  position: absolute;
  top: 10px;
  left: 120px;
}

.cf_table_convert .bonus {
  font-size: 20px;
  font-weight: bold;
  border-style: none;
  position: relative;
}

.cf_table_convert .note_td {
  padding: 12px;
  border-right-style: none;
  border-bottom-style: none;
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
}

.cf_table_convert .note_td_data {
  padding: 12px;
  border-left-style: none;
}

.cf_table_convert .contract_price td:first-child {
  padding: 12px;
  text-align: left;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
}

.cf_table_convert .contract_price td:nth-child(2) {
  padding: 12px;
  text-align: right;
  border-left-style: none;
  border-right-style: none;
}

.cf_table_convert .contract_price td:nth-child(3) {
  padding: 12px;
  text-align: left;
  border-left-style: none;
}

.cf_table_convert .manufacture_bonus td:first-child {
  padding: 12px;
  text-align: left;
  border-top-style: none;
  border-right-style: none;
}

.cf_table_convert .manufacture_bonus td:nth-child(2),
.cf_table_convert .manufacture_bonus td:nth-child(3) {
  padding: 12px;
  text-align: left;
  border-left-style: none;
  border-right-style: none;
}

.cf_table_convert .manufacture_bonus td:nth-child(4) {
  padding: 12px;
  text-align: left;
  border-left-style: none;
}

.cf_table_convert .td_content {
  padding: 12px;
  font-size: 20px;
  text-align: left;
}

.cf_table_convert .approved {
  padding: 10px;
  text-align: left;
  vertical-align: top;
}

.contract_total {
  border-style: none;
  font-size: 20px;
}
