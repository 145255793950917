.setting {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 120px;
}

.form-input {
  display: flex;
  align-items: flex-start;
}
.label {
}
.devider{
  height: 1px;
  background-color: black;
  margin: 20px 0px;
  width: 100%;
  transform: scaleX(1.25);
}

.item {
  display: flex;
  flex-direction: column;
  gap:8px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 16px; */
  width: 100%;
}

.item-body{
  width: 100%;
  padding-left: 42px;
}

textarea {
  width: 100%;
  max-width: 290px;
  min-width: 290px;
}
.add-btn{
  background-color: #eee;
  border: none;
  padding: 4px 10px;
  margin-left: calc(100% - 70px);
}
.cancel-btn{

}
.emailtemplate >div {
  height: 300px;
}

.MuiContainer-root > div {
  /* padding: 0px; */
  
}

/* .Toastify {
  width: 100%;
  position: sticky;
  top: 0px;
}
.Toastify >div {
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  background-color: white;
  opacity: 1;
}

.Toastify div {
  opacity: 1;
} */