.page5 {
  padding-top: 10%;
  /* font-family: MinionPro-bold_p;
  font-weight: bold; */
  font-size: 17px;
  letter-spacing: 0.13px;
  text-align: center;
}

.pdf-page {
  width: 1035px;
  background-color: white;
  margin: 0 auto 10px;
  padding: 18px 30px 2px 20px;
  page-break-before: always;
}

.pdf-page-portrait {
  width: 1175px !important;
  height: 685px;
  background-color: white !important;
  margin: 0 auto 10px !important;
  padding: 18px 30px 2px 20px !important;
  page-break-before: always !important;
  font-size: 14px;
}

.hr-line {
  margin: 70px 0px;
  border-top: 3px dashed black;
  text-align: center;
}

.text-center {
  text-align: center;
}

.text-red {
  color: red;
}

.text-small {
  font-size: small;
}
