.cc_flex_wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-50 {
  flex: 50%;
}

.cc_left_align {
  text-align: left;
}

.cc_right_align {
  text-align: right;
}

.cc_bar {
  padding: 5px;
}

.cc_bordered_container {
  border: 2px solid black;
}

.cc_credit_card {
  width: 70%;
  justify-content: space-around;
}

.cc_content {
  text-align: justify;
  margin-top: 2%;
  font-style: italic;
  font-size: 22px;
}

.cc_container {
  padding: 5%;
  /* font-family: MinionPro-bold_p;
  font-weight: bold; */
  font-size: 18px;
  letter-spacing: 0.13px;
  margin-bottom: 350px;
}

.cc_flex-50 {
  flex: 50%;
}

.cc_flex-30 {
  flex: 30%;
}

.cc_title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 2%;
}


.cc_table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.cc_table td, th {
  border: 1px solid;
  text-align: left;
  padding: 12px;
  text-align: left;
}

.cc_table .cc_thead {
  /* background-image: url('/public/images/gray4.png');
  background-repeat: repeat; */
  /* background-color: #aaa; */
  text-align: center;
}
.cc_table img{
  margin-left: -12px;
  margin-bottom: 3px;
  margin-top: -12px;
  width: calc(100% + 24px);
  height: 60px;
}
.cc_table .cc_tdtitle {
  text-align: right;
  width: 20%;
}
