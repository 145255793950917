.table-bottom {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.width-50px {
  width: 50px;
}

.margin-2 {
  margin: 0px 2px !important;
}

.small-letter {
  font-size: 12px;
}

.small-input {
  margin-bottom: 1px !important;
  height: 15px;
}

.table-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.inside {
  text-align: right !important;
}

.outside {
  text-align: left !important;
}

.iacLblPos {
  margin: 12px auto 2px auto;
  padding: 0.7em;

  width: 270px !important;
  max-width: 270px !important;
}

.input_label {
  display: inline-block;
  vertical-align: middle;
  width: 40%;
  text-align: center;
}

.pos_bottom > label {
  display: block;
}

.lbl {
  color: black;
  vertical-align: middle;
} /* style all labels */
