.no-outline {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  /* background-color: #eee; */
}

.no-outline:focus {
  outline: none;
}

.wrapper-space-between {
  display: flex;
  /*flex-wrap: wrap;*/
  align-items: center;
  justify-content: space-between;
}

.top-padding-4 {
  padding-top: 1%;
}

.s1 {
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  color: #000;
}

.input-box {
  flex: auto;
}

.flex-input {
  display: flex;
}

.contact-input {
  text-align: left !important;
  flex: auto;
}

.input-line {
  margin-top: 3px;
}