.roundup {
  display: flex;
  justify-content: space-between;
  background-color: rgb(230, 230, 230);
  padding: 2px;
}

.roundup-flex {
  flex: 100%;
}

.roundup-input {
  background-color: rgb(230, 230, 230) !important;
}