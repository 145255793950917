.Content p {
    text-align: justify;
    margin: 2.5%;
  }
/*contact*/
  .no-outline {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    /* background-color: #eee; */
  }
  
  .no-outline:focus {
    outline: none;
  }
  
  .wrapper-space-between {
    display: flex;
    /*flex-wrap: wrap;*/
    align-items: center;
    justify-content: space-between;
  }
  
  .top-padding-4 {
    padding-top: 1%;
  }
  
  .s1 {
    font-size: 15px;
    font-family: 'Times New Roman', Times, serif;
    color: #000;
  }
  
  .input-box {
    flex: auto;
  }
  
  .flex-input {
    display: flex;
  }
  
  .contact-input {
    text-align: left !important;
    flex: auto;
  }
  
  .input-line {
    margin-top: 3px;
  }
/**/

/** header */
.header-wrapper {
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: space-between;
    /* height: 150px; */
  }
  
  .content {
    margin: 12px auto;
    text-align: left;
  }
  
  .title {
    text-align: center;
  }
  
  p {
    margin: 0;
    padding: 0;
  }
  
  .header__left-content,
  .header__right-content {
    position: relative;
    font-size: 21px;
  }
  
  .header__left-content > div {
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .header__right-content > div {
    float: right;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .header__left-content__title,
  .header__right-content__title {
    font-size: 30px;
    font-weight: bold;
  }
  
  .position-absolute {
    position: absolute;
  }  
/** */
.easytopay-title-line {
    width: 100%;
    text-align: center;
    font-size: 26px;
    padding: 8px 0px;
    background-color: #2F5696;
    z-index: 1;
}
.easytopay-title {
  filter: brightness(0) invert(1);
}
.easytopay-box-container {
    width: 30%;
    flex-direction: column;
    border: #000 solid 1px;
}
.easytopay-box-header {
    text-align: center;
    background-color: #B4C6E7;
    font-size: 23px;
    z-index: 1;
}
.easytopay-box-content {
    display: flex;
    justify-content: center;
    gap: 8px;
    font-weight: 900;
    font-size: 26px;
    padding: 4px 32px;
}
/*payment bar*/
/* Container to hold everything */
.container {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    filter: brightness(1);
    height: 78px;
    position: relative;
    margin-top: 32px;
  }
  
  /* Left Circle */
  .leftCircle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 45px solid #2e5b9a;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    box-shadow: 0 0 0 4pt #fff;
    z-index: 11;
  }
  .leftCircle span {
    filter: brightness(0) invert(1);
  }
  /* Middle section for text */
  .details {
    padding: 12px 0px;
    width: 70%;
    background-color: #2e5b9a;
    z-index: 10;
    position: relative;    
  }
  .details::before {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    content: '';
    position: absolute;
    left: -20px; 
    top: 50%;
    transform: translateY(-50%);
    width: 80px;  
    height: 100%; 
    border: 35px solid #2e5b9a; 
    z-index: -1; 
  }
  .details-description {
    min-height: 18px;
    filter: brightness(0) invert(1);
    text-align: center;
  }
  .details-title {
    min-height: 24px;
    filter: brightness(0) invert(1);
    text-align: center;
  }
  .details::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 49px solid #2e5b9a; 
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    position: absolute;
    right: -48px; 
    top: 50%;
    transform: translateY(-50%);
  }
  .details h2 {
    font-size: 1.25rem;
    margin: 0;
  }
  
  .details p {
    font-size: 0.9rem;
    margin: 5px 0 0;
  }
  
  /* Right section for payment */
  .payment {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    color: #344a8b;
    background-color: #fff;
    text-align: right;
    flex-grow: 1;
    border: #2e5b9a solid 1px;
    line-height: 1;
    height: 70px;
    padding: 8px 16px;
    padding-left: 50px;
  }
  
  .currency {
    font-size: 1.5rem;
    color: black;
    font-weight: 900;
  }
  
  .amount {
    font-size: 1.8rem;
    font-weight: bold;
    color: black;
  }
  
  .payment p {
    margin-top: 5px;
    font-size: 0.9rem;
    color: #7b7b7b;
  }
  .details-border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #1d3f72; /* Slightly darker blue for contrast */
    z-index: 0; /* Push it behind the details */
  }
  .extra:first-child {
    max-width: 15%;
    right: calc(50%);
    top: -50px;
  }
  .extra:last-child {
    max-width: 15%;
    right: 50px;
    top: -50px;
  }
  .extra:nth-child(2) {
    max-width: 15%;
    right: calc(25%);
    top: -50px;
  }
  .period{
    position: absolute;
    top: -24px;
    right: 50px;
    color: #1d3f72;
    font-size: 20px;
  }
  .hexagon-star-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    left: 60%;
    position: absolute;
    z-index: 50;
    transform: translateY(-10px);
  }

  .hexagon-star-text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(0) invert(1);
    font-weight: bold;
    font-size: 20px;
  }
  .hexagon-star {
    width: 150px;
    height: 150px;
  }
  .extras-container{
    display: flex;
    width: 100%;
  }
  .e-arrow{
    min-width: 35%;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .e-arrow-img{
    height: 70px;
    width: 70px;
  }
  .e-arrow-text{
    min-width: 70%;
    display: flex;
    justify-content: center;
    gap: 8px;
    font-weight: bold;
    font-size: 24px;
  }
/**/
.b3-banner-container {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    color: white;
    filter: brightness(1);
    height: 78px;
    position: relative;
    margin-top: 32px;
  }
  
  /* Left Circle */
  .b3-banner-leftCircle {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 45px solid #2e5b9a;
    filter: brightness(1);
    border-radius: 50%;
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    outline: #fff solid 0px;
    z-index: 50;
  }
  /* Middle section for text */
  .b3-banner-details-1 {
    padding: 12px 0px;
    width: 20%;
    background-color: #fff;
    border: #2e5b9a solid 1px;
    border-left: none;
    z-index: 10;
    position: relative;
  }
  
  .b3-banner-details-1::before {
    content: '';
    position: absolute;
    left: -20px; 
    top: 50%;
    transform: translateY(-50%);
    width: 80px;  
    height: 100%; 
    border-top: #2e5b9a solid 1px;
    border-bottom: #2e5b9a solid 1px;
    z-index: -1; 
  }
  .b3-banner-details-2 {
    padding: 12px 0px;
    width: 20%;
    background-color: #fff;
    border: #2e5b9a solid 1px;
    z-index: 10;
    position: relative;
  }

  .b3-banner-details {
    padding: 12px 0px;
    width: 30%;
    background-color: #2e5b9a;
    z-index: 10;
    position: relative;
  }
  .b3-banner-details-description {
    min-height: 18px;
    color: white !important;
    filter: brightness(1);
    text-align: center;
  }
  .b3-banner-details-title {
    min-height: 24px;
    text-align: center;
  }
  .b3-banner-details::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 49px solid #345E9D; 
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    position: absolute;
    right: -48px; 
    top: 50%;
    transform: translateY(-50%);
  }
  .b3-banner-details-1 h2 {
    font-size: 1.25rem;
    margin: 0;
  }
  .b3-banner-details-2 h2 {
    font-size: 1.25rem;
    margin: 0;
  }
  .b3-banner-details h2 {
    font-size: 1.25rem;
    margin: 0;
  }
  
  .b3-banner-details p {
    font-size: 0.9rem;
    margin: 5px 0 0;
  }
  
  /* Right section for payment */
  .b3-banner-payment {
    display: flex;
    justify-content: flex-end;
    gap: 5%;
    align-items: center;
    color: #344a8b;
    background-color: #fff;
    text-align: right;
    flex-grow: 1;
    border: #2e5b9a solid 1px;
    line-height: 1;
    height: 70px;
    padding: 8px 16px;
    padding-left: 50px;
  }
  
  .b3-banner-currency {
    font-size: 1.5rem;
    color: black;
    font-weight: 900;
  }
  
  .b3-banner-amount {
    font-size: 1.8rem;
    font-weight: bold;
    color: black;
  }
  
  .b3-banner-payment p {
    margin-top: 5px;
    font-size: 0.9rem;
    color: #7b7b7b;
  }
  .b3-banner-details-border {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #1d3f72; /* Slightly darker blue for contrast */
    z-index: 0; /* Push it behind the details */
  }
  .b3-banner-period {
    display: flex;
  }
  .b3-banner-period:nth-child(1){
    position: absolute;
    top: -24px;
    left: 15%;
    color: #1d3f72;
    font-size: 20px;
  }
  .b3-banner-period:nth-child(2){
    position: absolute;
    top: -24px;
    left: 35%;
    color: #1d3f72;
    font-size: 20px;
  }
  .b3-banner-period:nth-child(3){
    position: absolute;
    top: -24px;
    right: 50px;
    color: #1d3f72;
    font-size: 20px;
  }
  .b3-banner-hexagon-star-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    left: 60%;
    position: absolute;
    z-index: 50;
  }

  .b3-banner-hexagon-star-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    filter: brightness(1);
    font-weight: bold;
    font-size: 20px;
  }
  .b3-banner-hexagon-star {
    width: 150px;
    height: 150px;
  }
  .b3-banner-extras-container{
    display: flex;
    width: 100%;
  }
  .b3-banner-e-arrow{
    min-width: 35%;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }
  .b3-banner-e-arrow-img{
    height: 70px;
    width: 70px;
  }
  .b3-banner-e-arrow-text{
    min-width: 70%;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;
  }
  .etp-footer-text {
    max-width: 80%;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .etp-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .etp-footer-banner {
    padding: 8px;
    background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    filter: brightness(1);
    font-weight: bold;
    margin-bottom: 24px;
  }
  .etp-footer-banner-1 {
    font-size: 22px;
    z-index: 10;
    filter: brightness(0) invert(1);
    text-align: center;
  }
  .etp-footer-banner-2 {
    font-size: 20px;
    z-index: 10;
    filter: brightness(0) invert(1);
    text-align: center;
  }
  .bg-img-print {
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
  }
  .estpay-relative {
    position: relative;
  }