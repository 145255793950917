.salespersonandowner {
  display: flex;
  justify-content: space-between;
}

.salespersonandowner > * {
  width: 80vw;
  flex-grow: 1;
  /* background-color: red; */
  margin: 30px;
}

.salesperson input {
  font-size: 1.2em;
}