.calculatetable {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.box {
  border: 3px solid black;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.table-box {
  border: 1px solid black;
}

.financing-box {
  margin-top: 8px;
  font-size: 16px;
  border: 3px solid black;
}

.total-form {
  text-align: right;
}
.width-100 {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.width-89 {
  width: 89%;
}

.width-88 {
  width: 88%;
}

.width-86 {
  width: 86%;
}

.width-84 {
  width: 84%;
}

.width-82 {
  width: 82%;
}

.width-81 {
  width: 81%;
}

.width-80 {
  width: 80%;
}

.width-74 {
  width: 74%;
}

.width-73 {
  width: 73%;
}

.width-70 {
  width: 70%;
}

.width-60 {
  width: 60%;
}

.width-50 {
  width: 50%;
  /* margin-top: 2px; */
}

.width-40 {
  width: 40%;
}

.width-38 {
  width: 38%;
}

.width-30 {
  width: 30%;
}

.width-20 {
  width: 20%;
}

.width-10 {
  width: 10%;
}

.width-100px {
  width: 100px;
}

.blue-text {
  color: blue;
}

.black-text {
  color: black !important;
}

.total-input {
  font-size: 30px;
  font-weight: bold;
  width: 70%;
  text-align: center !important;
}

.month-input {
  font-size: 20px;
  font-weight: bold;
  width: 50%;
  color: red !important;
  text-align: center !important;
}

.payment-total {
  height: 50%;
}

.financing {
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
}

.bold-letter {
  font-weight: bold;
  letter-spacing: -1px;
}

.handle-box {
  background-color: white;
  border: 2px solid black;
  width: 32px;
  height: 32px;
}

.financing-area {
  margin-top: 20px;
}

.tbl-title {
  font-weight: bold;
  text-align: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fdm__box_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
