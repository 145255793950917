.d-flex {
  display: flex;
}

.msh__header {
  display: flex;
}

.msh__header__center {
  text-align: center;
}

.m-0 {
  margin: 0;
}

.m-5 {
  margin: 5px;
}

.msh__header__left-table,
.msh__header__right-table,
.msh__body-table {
  border-collapse: collapse;
  border: 2px solid black;
  color: black;
  font-size: 12px;
}

.msh__header__left-table tr td {
  border: 2px solid black;
  font-weight: bold;
}

.msh__header__right-table tr td {
  border: 2px solid black;
  font-weight: bold;
}

.msh__header__right-table .non-outline {
  border: none;
}

/* .msh__hover-left-table {
  position: relative;
  opacity: 1;
  z-index: 1;
  overflow: hidden;
  transition: all 1s;
}

.msh__hover-left-table:hover {
  cursor: pointer;
  opacity: 0.5;
} */

/* .msh__hover-left-table {
  white-space: nowrap;
} */

.msh__hover-left-table{
  width: 30%;
}

.msh__hover-button {
  opacity: 1;
  transition: all 1s;
}

/* .msh__hover-left-table:hover .msh__hover-button {
  opacity: 1;
} */

.msh__body-table {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
}

.msh__body-table tr,
.msh__body-table tr,
.msh__header__right-table tr,
.msh__header__left-table tr {
  height: 6vh;
}

.msh__body-table thead tr th {
  border: 2px solid black;
  padding: 5px;
  font-size: 12px;
}

.msh__body-table thead tr th:nth-child(16) {
  width: 250px;
  text-align: left;
}

.msh__body-table thead tr th:nth-child(1),
.msh__body-table thead tr th:nth-child(2) {
  text-align: left;
}

.msh__body-table tbody tr td {
  text-align: center;
  border: 1px solid black;
  line-height: 12px;
}

.msh__body-table tbody tr:hover {
  background-color: rgb(211, 218, 250);
}

.msh__body-table:hover,
.msh__header__left-table:hover,
.msh__header__right-table:hover {
  cursor: pointer;
}

.msh__header_left-table-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  /*height: calc(90vh * 0.9);*/
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.msh__header_main-table-modal {
  /* display: flex;
  flex-wrap: wrap; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 90vw; */
  height: calc(78vh * 0.9);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: '70vh';
}

.msh__header_main-table-modal .p-line {
  display: flex;
  width: 300px;
  padding: 10px 0px;
}

.msh__header_main-table-modal .p-line label {
  width: 40%;
  text-align: right;
  padding-right: 10px;
}

.msh__header_main-table-modal
  .p-line
  > :last-child:not(.measure-sheet__check-box) {
  width: 60%;
}

.modal_footer {
  padding: 10px 0px;
  display: flex;
  flex: 100%;
  justify-content: space-around;
}

.msh-text {
  font-size: 12px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.flex-col-10 {
  flex: 10%;
}
.flex-col-20 {
  flex: 20%;
}
.flex-col-30 {
  flex: 30%;
}
.flex-col-40 {
  flex: 40%;
}
.flex-col-50 {
  flex: 50%;
}
.flex-col-60 {
  flex: 60%;
}
.flex-col-70 {
  flex: 70%;
}
.flex-col-80 {
  flex: 80%;
}
.flex-col-90 {
  flex: 90%;
}
.flex-col-100 {
  flex: 100%;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items__end {
  align-items: end;
}

.justify-content__end {
  justify-content: flex-end;
}

.justify-content__space-between {
  justify-content: space-between;
}

.justify-content__space-around {
  justify-content: space-around;
}

.borderd {
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.top-borderd {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
}

.bottom-borderd {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

.right-borderd {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: black;
}

.msh-width-100px {
  width: 250px;
}

.display-inline-block {
  display: inline-block;
}

.ms_select {
  padding: 6px;
  font-size: 16px;
  border-style: none;
  width: 100%;
}

.measure-sheet__check-box {
  width: 20px;
  height: 20px;
  border: 1px solid black;
}

.measure-sheet__big-font {
  font-size: 1.2em;
}

.measure-sheet__draw-box {
  text-align: left;
  height: 100%;
  border: 3px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
}

.msh__header__right-table tr:nth-child(3) td:nth-child(2) input{
  padding: 8px;
  font-size: 16px;
}