.paragraph {
  margin: 10px;
  font-size: 13px;
}

textarea.Notes {
  width: 100%;
  height: 150px;
}

.FloorLayer {
  display: flex;
}

.Floor {
  width: 30%;
  margin: 2%;
}

.Rectangle {
  height: 150px;
  border: 1px solid black;
}

.InputOfFloor {
  display: flex;
  flex-direction: row-reverse;
}

.RightDirection {
  margin-top: 8px !important;
}
