.page_title {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: 2em;
}

.information {
  text-align: left;
}

.information-input {
  height: 25px;
  margin-bottom: 4px;
}
