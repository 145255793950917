.notice-box {
  border: 2px solid black;
  text-align: justify;
  padding: 3px 3px;
  margin-top: 1px;
}

.text-align-center {
  text-align: center;
}

.alert-content {
  font-weight: bold;
  font-size: small;
  text-align: justify;
}
/* 
.input-field {
  display: inline-block;
  position: relative;
}

.input-field label {
  position: absolute;
  top: 25px;
} */

.wrapper-space-around {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.wrapper-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.middle-input {
  width: 60%;
}

.email-form {
  width: 60%;
}

.bottom-box {
  line-height: 20px;
  /*letter-spacing: -1px;*/
  font-family: sans-serif;
  margin-top: 10px;
}

.notice-owner {
  line-height: 14px;
  letter-spacing: -0.5px;
  font-family: sans-serif;
}

.salesman-owner {
  margin-top: 10px;
}
