.wof__header {
  display: flex;
  justify-content: flex-end;
}
.wof__contact {
  flex-basis: 40%;
}
.wof__contact__table_convert {
  width: 100%;
  border: 2px solid black;
  border-collapse: collapse;
  margin: 0;
}

.wof__contact__table_convert td {
  border: 2px solid black;
}

.wof-top-left-text {
  font-size: 10px;
  font-weight: bold;
  margin: 0;
}

.wof__contact-text {
  color: blue;
  text-align: right;
  margin: 0;
  font-size: 1.2rem;
}

.wof__body-table_convert {
  width: 100%;
  border: 2px solid black;
  border-collapse: collapse;
  margin: 0;
}

.wof__body-table_convert th {
  border: 1px solid black;
  font-size: 10px;
}

.wof__body-table_convert > tbody > tr > td {
  border: 1px solid black;
  font-size: 12px;
  color: blue;
  text-align: center;
  line-height: 12px;
}

.wof__body-table_convert tr {
  height: 4vh;
}

.wof__contact__table_convert:hover,
.wof__body-table_convert:hover {
  cursor: pointer;
}

.wof__body-table_convert tbody tr:hover {
  background-color: rgb(211, 218, 250);
}

.wof__body__footer__table {
  border-collapse: collapse;
  border: 2px solid black;
  font-size: 10px;
  color: black;
}

.wof__body__footer__table thead tr {
  height: 6vh;
}

.wof__body__footer__table td,
.wof__body__footer__table th {
  border: 2px solid black;
}

.wof__body__footer {
  display: flex;
  justify-content: space-between;
}

.wof__body__footer__table__p {
  margin: 1px;
  color: black;
  text-align: left;
  padding-left: 10px;
}

.wof__footer {
  display: flex;
}

.wof__footer__col {
  flex: 30%;
  flex-grow: 1;
}

.wof__table_modal_convert {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: calc(90vh * 0.9);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .p-line {
    flex: 100%;
  }
}

.flex-col-10 {
  flex: 10%;
}
.flex-col-20 {
  flex: 20%;
}
.flex-col-30 {
  flex: 30%;
}
.flex-col-40 {
  flex: 40%;
}
.flex-col-50 {
  flex: 50%;
}
.flex-col-60 {
  flex: 60%;
}
.flex-col-70 {
  flex: 70%;
}
.flex-col-80 {
  flex: 80%;
}
.flex-col-90 {
  flex: 90%;
}
.flex-col-100 {
  flex: 100%;
}

.d-flex {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.wof__footer-text {
  font-size: 10px;
  margin: 1px;
}

.wof-text {
  font-size: 10px;
  margin: 1px;
  text-align: left;
  color: black;
}

.underline-text {
  text-decoration: underline;
}

.bg-black {
  background-color: black;
  color: white;
}

.p-5 {
  padding: 5px;
}

.borderd {
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.top-borderd {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: black;
}

.bottom-borderd {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

.right-borderd {
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: black;
}

table.wof__body-table_convert td {
  height: 1.8em;
}

.wof__main-table-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 77vw;
  height: calc(77vw * 0.6);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.left-align {
  text-align: left;
}

.window-order__draw-box_convert {
  height: 150px;
  border: 3px solid black;
  position: relative;
}

.right-align {
  text-align: right;
}

.border-bottom {
  border-bottom: 3px solid black;
}

.width-20 {
  width: 20%;
}
.width-40 {
  width: 40%;
}
.width-50 {
  width: 50%;
}
.margin-top-30px {
  margin-top: 30px;
}

.blue-font {
  color: blue;
}

.msh__header__left {
  display: flex;
  align-items: end;
}
.msh__header__right {
  margin-bottom: 30px;
}

.right-direction {
  float: right;
}

.justify-end {
  justify-content: end;
}

.window-order__draw-box_convert {
  text-align: left;
}

.black-font {
  color: black;
}

.wo_sales {
  margin-top: 5px;
}

.mySign_convert {
  position: absolute;
  top: 0;
  left: 0;
}

td.window-order__X-td_convert {
  width: 10px;
}
