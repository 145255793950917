.patio-door-order,
.patio-door-order__table-modal {
  font-size: 13px;
  font-family: Arial;
  text-align: left;
  white-space: nowrap;
}

.patio-door-order .main-table {
  margin: auto;
}

* {
  box-sizing: border-box;
}

.patio-door-order * {
  overflow: hidden;
}

.patio-door-order__CheckBox > * {
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.patio-door-order table td,
.patio-door-order table td * {
  height: 30px;
}

div.Patio_Information {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.BlackBar {
  background-color: black;
  height: 1em;
}

.patio-door-order ul > *,
.patio-door-order__table-modal ul > * {
  margin-top: 9px;
}

.patio-door-order__CheckBox {
  height: calc(1em + 2px);
  border-bottom: 1px solid black;
}

.patio-door-order__CheckBox-Width1 {
  width: 30px;
}

.patio-door-order__CheckBox-Width2 {
  width: 60px;
}

.patio-door-order__CheckBox-Width3 {
  width: 90px;
}

.space {
  height: calc(1em + 2px);
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.patio-door-order ul,
.patio-door-order__table-modal ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.col.border:not(.col:first-child) {
  margin-left: 30px;
}

.col ul:last-child {
  padding-right: 10px;
}

.border-left,
.border:last-child:not(.border:first-child),
.flex__right-direction.connected-border .border:first-child {
  border-left: 1px solid black;
}

.patio-door-order__table-modal .connected-border {
  border: 1px solid black;
}
.border-bottom {
  border-bottom: 1px solid black;
}

.border,
.border:not(.border:last-child) {
  border: 1px solid black;
}

.flex .col {
  height: 100%;
}
.col.border:first-child {
  border-left: none;
  border-right: 1px solid black;
}
.col.border:last-child {
  border-left: 1px solid black;
  border-right: 0px;
}

.connected-border {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.connected-border .border,
.connected-border .border:last-child {
  border: none;
}

.patio-door-order__table-modal .border,
.patio-door-order__table-modal .col.border:first-child {
  border: 1px solid black;
}

.connected-border:last-child,
.border:last-child {
  border-right: 1px solid black;
}

.big-font {
  font-size: 21px;
}

.blue-font {
  color: Blue;
}

.Width300px {
  width: 300px;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.italic-font {
  font-style: italic;
}

.white-color {
  color: white;
}

.width-30 {
  width: 30%;
}

.width-35 {
  width: 35%;
}

.width-70 {
  width: 70%;
}
.width-90 {
  width: 90%;
}

.flex__right-direction {
  flex-direction: row-reverse;
}

.bottom-0 {
  position: absolute;
  bottom: 0px;
}

.patio-door-order__table-modal .bottom-0 {
  position: relative;
  margin-top: 30px;
}

.patio-door-order__table-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.patio-door-order__table-modal .width-30,
.patio-door-order__table-modal .width-35,
.patio-door-order__table-modal .width-70 {
  width: 100%;
}

.component-i-j {
  position: relative;
}

.component-i-j:not(.patio-door-order__table-modal .component-i-j) {
  cursor: pointer;
}

.component-i-j:hover:not(.patio-door-order__table-modal .component-i-j) {
  background-color: rgb(229, 229, 252);
}

.pdo_custom_size {
  text-align: center;
  height: 56px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: solid 1px black;
}

.pdo_custom_size .input_container {
  padding: 7px;
}

.pdo_custom_size input {
  font-size: 20px;
  text-align: center;
}
