.x-small {
  font-size: x-small;
}

.xx-small {
  font-size: xx-small;
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer {
  margin-top: 40px;
}