input.bottom-outline {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: aliceblue;
  text-align: center;
  margin: 0 4px;
  color: blue;
}

input.bottom-outline:focus {
  outline-style: none;
  background-color: white;
}
